require('./bootstrap');

// Slick
require('slick-carousel/slick/slick.min')

// AOS
import AOS from 'aos';

AOS.init({disable: 'mobile'});

function initMap() {
    let map;
    map = new google.maps.Map(document.getElementById("map"), {
        zoom: 8,
        center: {lat: 49.776, lng: 15.542},
        //   mapId: '4549bfe3e0f5b1b1',
    });


    // const svgMarker = {
    //     path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    //     fillColor: "#F15B22",
    //     fillOpacity: 0.9,
    //     strokeWeight: 0,
    //     rotation: 0,
    //     scale: 2,
    //     anchor: new google.maps.Point(15, 30),
    //   };

    // var marker = new google.maps.Marker({
    //     position: { lat: 48.9578906083169, lng: 17.011635505518466 },
    //     title:"Hello World!",
    //     icon: svgMarker,
    // });

    // To add the marker to the map, call setMap();
    marker.setMap(map);
}

window.initMap = initMap;
